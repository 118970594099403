import React, { Component } from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import Formsy from 'formsy-react';

import Layout from "../components/layout"
import Wizard from "../components/wizard"
import confirmIcon from "../images/gray/6.svg"
import bottomLine from "../images/line.png"

import quest from "../images/questions-icon.svg"
import MyInput from '../components/MyInput';
import MyInputMask from "../components/MyInputMask";
import MyCheckbox from "../components/MyCheckbox";
import closeChat from '../images/close-icon.svg';

import pig1 from "../images/pig1.svg"
import pig2 from "../images/pig2.svg"
import pig3 from "../images/pig3.svg"

import SEO from "../components/seo"
import * as actionCreators from '../store/actions';
import { restrictRouteForStep } from "../core/util";
import { isAuthenticated,getAuthenticatedUser } from '../core/util';

class ConfirmPage extends Component {

  state = {
    check1: false,
    check2: false,
    check3: false,
    Seal1:0,
    Seal2:0,
    Seal3:0,
    finaldata: null,
    curplan : null,
    apierror:null,
    stackCost:0,
    dividend:null,
    planCost:0,
    showalert:0,
    couponCode:null,
    couponStat:0,
    discountType:null,
    discountVal:0,
    discountAmt:0,
    discountCode:null,
    totAmt:0,
    dateVal:null,
    initdate:null,
    postpay:0
  }

  componentDidMount = async() => {
    //if (this.props.step2response && !this.props.step2response.recordId) {
      this.props.showLoader();
      restrictRouteForStep(this.props.location);
      this.clearAllSteps(false)
      this.setState({dateVal:this.getMyDate(0,1),initdate:this.getMyDate(0,1)});
      let paramId = restrictRouteForStep(this.props.location);
      if (paramId) {
          await this.getFromDirect(paramId);
      }
      this.props.hideLoader();      
    //} 
  }

  getMyDate = (isToday,disply) => {
    let dte = new Date();
    if(isToday==0){
        dte.setDate(dte.getDate() + 1);
    }
    var dd = String(dte.getDate()).padStart(2, '0');
    var mm = String(dte.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = dte.getFullYear();
    if(disply)
    return (mm + '/' + dd + '/' + yyyy);
    else
    return (yyyy + '/' + mm + '/' + dd);
}

  getFromDirect = async(paramId) => {
    
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanById'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
    
    await fetch(uri,{
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            _id: paramId,
        })
      })
    .then(res => res.json())
    .then((res) => {
      if(res.status === 1){
        this.setState({ finaldata: res.data });
        var plans = res.data.plans;
        var step3 = res.data.step3;
        var feat = res.data.step1.vehicleFeature;
        var plnidx = '';
        Object.keys(plans).forEach(function(idx) {
            if(plans[idx]['name'] === step3.levelOfCoverage){
                plnidx = idx;
            }
        });

        var curplan = {};
        curplan.price = step3.monthlyPayment;
        curplan.productCode = plans[plnidx].productCode;
        
        
        if(typeof plans[plnidx]['deduct'][step3.payAtClaimTime] !== 'undefined'){
          curplan.basicPrice = plans[plnidx].actual+plans[plnidx]['deduct'][step3.payAtClaimTime];
        } else if(step3.termMonth){
          curplan.basicPrice = step3.monthlyPayment;
          curplan.termRate = plans[plnidx]['termRate'][step3.termMonth]
        } else {
          curplan.basicPrice = plans[plnidx].actual;
        }

        curplan.rideshare = 0;
        curplan.halfdiv = 0;
        curplan.fulldiv = 0;
        curplan.Commercial = 0;
        curplan.EnhanchedTech = 0;
        curplan.Canadian = 0;
        curplan.WPW = 0;
        curplan.LiftKit = 0;
        

        if(feat.isRideshare) curplan.rideshare = plans[plnidx]['surc']['RideShare'];
        if(feat.isCommercial) curplan.Commercial = plans[plnidx]['surc']['Commercial'];
        if(feat.isEnhanchedTech) curplan.EnhanchedTech = plans[plnidx]['surc']['EnhanchedTech'];
        if(feat.isCanadian) curplan.Canadian = plans[plnidx]['surc']['Canadian'];
        if(feat.isWPW) curplan.WPW = plans[plnidx]['surc']['WaitPeriodWaiver'];
        if(feat.isLiftKit) curplan.LiftKit = plans[plnidx]['surc']['LiftKit'];

        curplan.halfdiv = plans[plnidx]['surc']['HALFDIV'];
        curplan.fulldiv = plans[plnidx]['surc']['FULLDIV'];

        let seal1 = 0;
        if(plans[plnidx]['surc']['Seals and Gaskets'])
        seal1 = plans[plnidx]['surc']['Seals and Gaskets'];

        console.log("---------curplan-------------",curplan);

        this.setState({ planCost: curplan.price,curplan: curplan,dividend: '',Seal1: seal1});
        this.totAmt();
      } else {
        navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
      }
    });   
  };

  clearAllSteps = (isNavigate) => {
    this.props.clearAllSteps();
    if (isNavigate) {
      navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
    }
  }
  makepay = async() => {
    
    const { showLoader, hideLoader } = this.props;
    const { finaldata, dividend, curplan, stackCost, Seal3, discountCode, dateVal,postpay} = this.state;
    
    showLoader();
    
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/makepay'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
    let refid = "";
    if (isAuthenticated()) {
      let usrdata = getAuthenticatedUser();
      if(usrdata.email){
        refid = usrdata.email;
      }
    }
    await fetch(uri,{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
          _id: finaldata.step3.recordId,
          dividendType : dividend,
          dividendPrice : stackCost,
          Seals:Seal3,
          retailPrice : curplan.price,
          productCode: curplan.productCode,
          coupon:discountCode,
          startDate:dateVal,
          postpay:postpay,
          refid:refid,
          curplan:curplan
      })
    })
    .then(res => res.json())
    .then((res) => {
        hideLoader();
        if (res.status ===1) {
          let paramId = restrictRouteForStep(this.props.location);
          if(global.dealertoken && global.href) this.ahrefElement.click();
          navigate(`/congratulation/?q=${res.contractId}&id=`+paramId+(global.dealertoken?'&dealertoken='+global.dealertoken:''));
        } else {
          this.setState({ apierror: res.msg })
        }
    });
  };

  dividendChange = (type,val) => {
    
    let curplan= this.state.curplan;
    //console.log(curplan)
    if(type==="HALFDIV" && val){
      this.setState({ dividend: 'HALFDIV',stackCost: this.state.curplan.halfdiv});
      this.state.stackCost = this.state.curplan.halfdiv;
    } else if(type==="FULLDIV" && val){
      this.setState({ dividend: 'FULLDIV',stackCost: this.state.curplan.fulldiv});
      this.state.stackCost = this.state.curplan.fulldiv;
    } else {
      this.setState({ dividend: '',stackCost: 0});
      this.state.stackCost = 0;
    }
    this.totAmt()
    
  }

  SealChange = (val) => {
    console.log('val:'+val)
    if(val){
      this.setState({ Seal2:val,Seal3:this.state.Seal1});
      this.state.Seal3 = this.state.Seal1
    } else {
      this.setState({ Seal2:val,Seal3:0});
      this.state.Seal3 = 0
    }
    
    this.totAmt()
  }

  showCoupon = () => {
    this.setState({couponStat:1})
  }

  totAmt = () => {
    var { curplan, stackCost, Seal3, discountType, discountAmt, discountVal } = this.state;
    console.log('stackCost : ' + stackCost,'Seal3:'+Seal3)
    let tot = curplan.price + stackCost + Seal3;
    console.log(discountType, discountAmt, discountVal, tot)
    if(discountType == 'f'){
      discountAmt = discountVal;
    } else if (discountType == 'p'){
      discountAmt = Math.round(tot * discountVal / 100,0);
    }
    tot = tot - discountAmt;
    console.log({totAmt:tot,discountAmt:discountAmt })
    this.setState({totAmt:tot,discountAmt:discountAmt })
  } 

  couponChange = (val) => {
    this.setState({couponCode:val})
    if(val.length>3){
      this.setState({couponStat:2})
    } else {
      this.setState({couponStat:1})
    }
  }
  applyCoupon = async() =>{
    const { showLoader, hideLoader } = this.props;
    const { couponCode, curplan, stackCost, Seal3} = this.state;
    showLoader();
    
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/checkCoupon'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
    await fetch(uri,{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        coupon:couponCode,
      })
    })
    .then(res => res.json())
    .then((res) => {
        hideLoader();
        if (res.valid ===1) {
          //console.log(res)
          this.setState({ couponStat:3, discountType:res.type, discountVal:res.value,discountCode:couponCode })
        } else {
          this.setState({ couponStat:4, discountType:null, discountVal:0,discountCode:null })
        }
        this.totAmt();
    });
  }
  postpay = (value) => {
    this.setState({ postpay: value })
    if(!value)
        this.setState({ dateVal: this.getMyDate(0,1) })
  }
  dateChange = (dt) => {
    if(dt.length == 10){
       let splt = dt.split("/");
       let eDt = new Date(splt[2]+'-'+splt[0]+'-'+splt[1]);
       let nDt = new Date(this.getMyDate(1,0));
       let diffTime = (eDt - nDt);
       let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
       console.log(eDt,nDt, diffTime, diffDays);
  
       if(diffDays>1){
           this.setState({dateVal:dt})
       } else {
           this.setState({dateVal:null})
       }
       
       //let dif = eDt.getDate() - nDt.getDate();
       //console.log(dif+ '-------'+ splt[2]+'-'+splt[0]+'-'+splt[1])
    } else {
       this.setState({dateVal:null})
    }
     
   }

  render() {
    const { check1, check3, finaldata, apierror, curplan, stackCost,dividend,showalert,
       Seal1,Seal2,Seal3,couponStat,discountAmt,totAmt,dateVal, initdate,postpay } = this.state;
    if(finaldata && curplan){
    } else {
     
      return (
      <Layout layoutType="main" >
      <section>
          <Wizard></Wizard>
          <div className="custom-container">
            <div className="title-container last">
              <div className="icon-image">
                <img src={confirmIcon} alt="icon" />
              </div>
            </div>
          </div>
      </section>
      </Layout>
      )
    }
    
    
    return (
      <Layout layoutType="wizard" >
        {global.dealertoken?
          <a className="hideme" id="price-step7" href={global.href+"#price-step7"} ref={ahref => this.ahrefElement = ahref} target="_top">trigger</a>
        :''}
        <SEO title="Servicecontract.com - Confirm Plan" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
        <div className="chat-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
            <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
              <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
              </g>
            </g>
          </svg>
        </div>
        {showalert?
          <div className="success-container">
              <div className="success-inner small">
                  <div onClick={() => this.setState({showalert:0})} className="close-btn close">
                      <img className="" src={closeChat} alt="" />
                  </div>

                  <div className="content">
                      <div className="stackpop">
                          <h4>{showalert===1?'Half Stacks':'Big Stacks'}</h4>
                          {
                            showalert===1
                            ?
                            <p>Half Stacks costs $5/month, and gives you back half of the money that you paid us after
                            36 months, less the $5/month for the Half Stacks plan and any claims paid.<br />
                            <b>Here’s an easy example</b>: If your service contract costs $45/month, and you opt for the Half Stacks
                            plan, your total monthly payment will be $50. Over 36 months, you will pay us $1,620
                            ($45 x 36, remember, the $5 is not returned to you), and if you do not have any claims,
                            we will give you Half of that money back…$810! Using the same example, let’s say you
                            had $500 in claims over the 36 months, we would then give you $560 back ($1,620-$500
                            = $1,120…Half of $1,120 is $560).</p>
                            :
                            <p>Big Stacks! This costs $10/month and gives you all the money back that is not used, less the $10/month. So, using that same example as in Half Stacks, if no claims were made, we’d send you $1,620, and if there were $500 in claims, we’d send you $1,120. How’s that for fairness and transparency?!</p>
                          }
                          
                      </div>
                  </div>
              </div>
          </div>
        :''}
        <section className={global.dealertoken?'grayish':''}>
          <Wizard></Wizard>
          <div className="custom-container vehinfo">

            <div className="title-container last">
              <div className="icon-image">
                <img src={confirmIcon} alt="icon" />
              </div>
              <div className="title-text">
                <h1>{finaldata.step1.vehicleInfo.year} {finaldata.step1.vehicleInfo.maker} {finaldata.step1.vehicleInfo.model}</h1>
                {/* <p>Providing your Vehicle’s year, make, model, and mileage helps us narrow down your options.</p> */}
              </div>
            </div>
            <div className="body-content confirm-content">
              <div className="cofirm-left-content">
              <Formsy ref="dividForm">
                {curplan.halfdiv1 &&
                <div className="confirm-box">
                  <div className="confirm-box-inner">
                    <div className="stackhead">Stacks</div>
                    <div className="stackinfo">Please choose which stacks plan you'd prefer</div>
                   
                    <ul>
                      <li className="stacks">
                        <div className="stitle">No Stacks</div>
                        <div><img className="pig1" src={pig1} alt="info" /></div>
                        <div className="sinfo">Nah, I don't want any money back</div>
                        <div className="scost">$0</div>
                        <div>
                        <MyCheckbox
                          name="nodiv"
                          id="nodiv"
                          labelClass="check-container big"
                          value={dividend===''?1:0}
                          checkBoxChange={(e) => this.dividendChange('',e)}
                          label=""
                        />
                        </div>
                        
                      </li>
                      <li className="stacks">
                        <div className="stitle" onClick={() => this.setState({showalert:1})}>Half Stacks <img src={quest} alt="info" /></div>
                        <div><img className="pig2" src={pig2} alt="info" /></div>
                        <div className="sinfo">I’m a little risky, so let’s go for half my money back.</div>
                        <div className="scost">{"$"+curplan.halfdiv}</div>
                        <div>
                        
                        <MyCheckbox
                          name="halfdiv"
                          id="halfdiv"
                          labelClass="check-container big"
                          value={dividend==='HALFDIV'?1:0}
                          checkBoxChange={(e) => this.dividendChange('HALFDIV',e)}
                          label=""
                        />
                        </div>
                      </li>
                      <li className="stacks">
                        <div className="stitle" onClick={() => this.setState({showalert:2})}>Big Stacks <img src={quest} alt="info" /></div>
                        <div><img className="pig3" src={pig3} alt="info" /></div>
                        
                        <div className="sinfo">I only deal in big Stacks, give me all my money back!</div>
                        <div className="scost">{"$"+curplan.fulldiv}</div>
                        <div>
                        
                        <MyCheckbox
                          name="fulldiv"
                          id="fulldiv"
                          labelClass="check-container big"
                          value={dividend==='FULLDIV'?1:0}
                          checkBoxChange={(e) => this.dividendChange('FULLDIV',e)}
                          label=""
                        />
                        </div>
                      </li>
                     
                    </ul>
                    
                  </div>
                </div>
                }
                {Seal1?
                <div className="confirm-box">
                  <div className="confirm-box-inner">
                    {/* <ul>
                      <li className="setting-1"> */}
                        <MyCheckbox
                          name="cbSeal"
                          id="cbSeal"
                          labelClass="check-container big"
                          value={Seal2}
                          checkBoxChange={(e) => this.SealChange(e)}
                          label="Seals and Gaskets"
                        />
                        <div className="sinfo2">
                        For seals and gaskets unless required in conjunction with a Covered Repair, unless the Seals and Gaskets Surcharge has been selected on the Information Schedule. Fluid leaks are not covered. Minor loss of fluid or seepage is considered Normal Wear and Tear and not a Breakdown. No coverage will be provided.
                        </div>
                      {/* </li>
                    </ul> */}
                  </div>
                </div>
                :''}
                </Formsy>
                <div className="confirm-box">
                  <div className="confirm-box-inner">
                    <ul>
                      <li className="setting-1">
                        <span>Vin</span>
                        <p>{finaldata.step1.vehicleInfo.vin}</p>
                      </li>
                      <li className="setting-2">
                        <span>COVERAGE Plan</span>
                        <p>{finaldata.step3.levelOfCoverage}</p>
                      </li>
                      <li className="setting-3">
                        <span>Contract</span>
                        <p>{finaldata.step1.vehicleInfo.mileage} miles</p>
                      </li>
                      {/* <li className="setting-4">
                        <span>Months </span>
                        <p>{finaldata.step3.keepVehicle}</p>
                      </li> */}
                      <li className="setting-5">
                        {finaldata.step3.termMonth?
                          <><span>Months</span><p>{finaldata.step3.termMonth}</p></>
                          :
                          <><span>Deductible</span><p><sup>$</sup>{finaldata.step3.payAtClaimTime}</p></>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="confirm-box">
                  <div className="confirm-box-inner">
                    <ul className="alignment-setting">
                      {/* <li className="setting-6">
                        <div className="confirm-img">
                          <img src={person} alt="" />
                          <img className="person-img" src={person} alt=""/>
                        </div>
                      </li> */}
                      <li >
                        <span>Name</span>
                        <p>{finaldata.step4.firstName} {finaldata.step4.lastName}</p>
                      </li>
                      <li>
                        <span>Email</span>
                        <p>{finaldata.step4.email}</p>
                      </li>
                      <li >
                        <span>Street Address</span>
                        <p>{finaldata.step4.address}, {finaldata.step4.city}, <br />
                        {finaldata.step4.state} {finaldata.step4.zipcode}</p>
                      </li>
                      <li>
                        <span>Phone</span>
                        <p>{finaldata.step4.phone}</p>
                      </li>
                    </ul>
                    <div className="Edit-content cursor-pnt">
                      {/* <Link to="/profileedit"> */}
                        {/* <span>Edit</span>  <img src={EditIcon} alt="" /> */}
                      {/* </Link> */}
                    </div>

                  </div>
                </div>

                {finaldata.card.cardNo!="NA" &&
                <div className="confirm-box">
                  <div className="confirm-box-inner">
                    <h3>Payment Info</h3>
                    <ul>
                      <li>
                        <span>Card Number</span>
                        <p>**** **** **** {finaldata.card.cardNo}</p>
                      </li>
                      <li>
                        <span>Card Expiration</span>
                        <p>{finaldata.card.cardExp}</p>
                      </li>
                      {/* <li>
                        <span>Security Code/CVV</span>
                        <p>****</p>
                      </li> 
                      <li>
                        <span>Billing Zip</span>
                        <p>{finaldata.card.cardZip}</p>
                      </li>*/}
                    </ul>
                  </div>
                </div>
                }
              </div>
              <Formsy ref="confirmForm">
                <div className="cofirm-right-content">
                {finaldata.step3.termMonth==0 && finaldata.card.cardNo!="NA" &&
                <>  
                <div className="ccout">
                <div className="cctitle" onClick={() => {this.showCoupon()}}>Have a Coupon?</div>
                {couponStat>0 &&
                <div>
                  <MyInput
                      name="coupon"
                      type="text"
                      placeholder="Coupon Code"
                      value=""
                      className="ccbox"
                      valueChanges={(e) => this.couponChange(e)}
                    />
                  <button onClick={() => {this.applyCoupon()}} className={couponStat==1?"ccbtn disabled":"ccbtn"}>Apply</button>
                  {couponStat==4 &&
                  <div className="ccerr">Invalid Coupon Code</div>
                  }
                  {couponStat==3 &&
                  <div className="ccok">Discount Applied : ${discountAmt}</div>
                  }
                </div>
                }
                </div>  
                
                <div className="ccout">
                <div className="cctitle">
                <MyCheckbox 
                    name="postpay" 
                    value={postpay} 
                    id="postpay"
                    labelClass="check-container small postpay"  
                    label="Post Payment Date (MM/DD/YYYY)"
                    checkBoxChange={(e) => this.postpay(e)} 
                />
                </div>
                {postpay>0 &&
                <div>
                <MyInputMask name="startdate" value={initdate} id="startdate" mask="99/99/9999" className="ccbox" 
                valueChanges={(e) => this.dateChange(e)} />
                {!dateVal &&
                <div className="ccerr">Invalid Date</div>
                }
                </div>
                }
                </div>
                </>
                }
                <div className="amount-box">
                    <div className="amount-box-inner">
                      
                      <div className="summ">
                      <div className="row"><div className="title">Summary</div></div>
                      <div className="row"><div className="left">Plan</div>
                        <div className="right">${curplan.basicPrice}</div>
                      </div>
                      {curplan.rideshare?<div className="row"><div className="left">Rideshare</div><div className="right">${curplan.rideshare}</div></div>:''}
                      {curplan.Commercial?<div className="row"><div className="left">Commercial</div><div className="right">${curplan.Commercial}</div></div>:''}
                      {curplan.EnhanchedTech?<div className="row"><div className="left">EnhanchedTech</div><div className="right">${curplan.EnhanchedTech}</div></div>:''}
                      {curplan.Canadian?<div className="row"><div className="left">Canadian</div><div className="right">${curplan.Canadian}</div></div>:''}
                      {curplan.WPW?<div className="row"><div className="left">WaitPeriodWaiver</div><div className="right">${curplan.WPW}</div></div>:''}
                      {curplan.LiftKit?<div className="row"><div className="left">LiftKit</div><div className="right">${curplan.LiftKit}</div></div>:''}
                      {Seal3?<div className="row"><div className="left">{'Seals & Gaskets'}</div><div className="right">${Seal3}</div></div>:''}
                      
                      {curplan.halfdiv1 &&
                      <div className="row"><div className="left">Stacks</div>
                        <div className="right">$
                        {dividend!==''?dividend === 'HALFDIV' ? curplan.halfdiv : curplan.fulldiv: 0}
                        </div>
                      </div>
                      }

                      {discountAmt?<div className="row"><div className="left">{'Discount'}</div><div className="right">-${discountAmt}</div></div>:''}
                      
                      <div className="total"><div className="left">Total</div><div className="right">${totAmt}</div></div>
                      </div>
                      

                      <div className="tearm-checkbox">
                       
                        <MyCheckbox
                          name="test"
                          id="test"
                          labelClass="check-container small"
                          value={check1}
                          checkBoxChange={(e) => this.setState({ check1: e })}
                          label="I have read and agree to the terms of the SERVICE AGREEMENT"
                        />
                        <MyCheckbox
                          name="test2"
                          id="test2"
                          labelClass="check-container small"
                          checkBoxChange={(e) => this.setState({ check3: e })}
                          value={check3}
                          label={"I agree to be charged $"+ (totAmt) + (finaldata.step3.termMonth?"":" per month.")}
                          // +" per month for "+finaldata.step3.keepVehicle+" additional months"}
                        />

                       
                      </div>
                      <div className="noplanerr">{apierror}</div>
                      <button onClick={() => {this.makepay()}} disabled={!check1 || !check3 || !dateVal}
                        className={!check1 || !check3 || !dateVal ? 'disabled btn amount-btn' : 'btn amount-btn'}>PURCHASE PLAN</button>
                      {/* <button className="btn amount-btn">PURCHASE PLAN</button> */}
                      {/* </Formsy> */}
                    </div>
                    <div className="amount-btn-container">
                      {/* <h2>Download Samples</h2> */}
                      <a href="https://servicecontract.s3.amazonaws.com/servicecontract-com-sample-contract.pdf" target="_blank" rel="noopener" className="btn sample-btn">SAMPLE CONTRACT</a>
                      {/* <button className="btn sample-btn">FINANCE CONTRACT</button> */}
                    </div>

                  </div>
                </div>
              </Formsy>
            </div>
          </div>
          {global.dealertoken?'':
          <div className="vehicle-bottom-line bottom-line confirm-bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
          }
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    step2response: state.register.step2response,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPage);